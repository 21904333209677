// Import our custom CSS
import '../scss/styles.scss';

// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap';

import '../images/favicon.png'
import '../images/Sarah-Bell-Agri-logo.png'
import '../images/Sarah-Bell-Agri_Background.png'
import '../images/Sarah-Bell-Agri_logo_white.png'
import '../images/Sarah-Bell-Agri_Background_light.jpg'
import '../images/twitter-logo-black.png'
import '../images/twitter-logo-white.png'
import '../images/linkedin-logo-black.png'
import '../images/linkedin-logo-white.png'



//Open external links in new tab
document.querySelectorAll('a[href^="http://"], a[href^="https://"]').forEach(function (elem) {
    if (!elem.href.includes(window.location.hostname)) {
        elem.target = '_blank';
        elem.rel = 'noopener';
    }
});


window.onscroll = function () {
    scrollFunction()
};

function scrollFunction() {
    const logo = document.querySelector(".navbar-logo");
    const dropdown = document.querySelector(".dropdown-menu");
    const sticky = document.querySelector(".sticky-column");
    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
        logo.classList.add("navbar-logo-shrink");
        dropdown.classList.add("dropdown-menu-shrink");
        if (sticky) {
            sticky.classList.add("sticky-column-shrink");
        }
    } else {
        logo.classList.remove("navbar-logo-shrink");
        dropdown.classList.remove("dropdown-menu-shrink");
        if (sticky){
            sticky.classList.remove("sticky-column-shrink");
        }
    }
}



